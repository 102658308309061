import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import './css/WhyFreshiouz.css';

function WhyFreshiouz(){
    return(
        <Container className="why-freshiouz py-5">
        <h2 className="why-title mb-4">Why Choose Freshiouz?</h2>
        <p className="why-intro">
          At Freshiouz, we’re passionate about delivering not just the finest quality meat but also an experience you can trust and savor. Here’s why Freshiouz is your go-to source for fresh, flavorful meat, delivered right to your door.
        </p>
        <Row className="why-features">
          <Col md={12}>
            <div className="why-feature">
              <h5><strong>1. Unmatched Freshness and Quality</strong></h5>
              <p>We source only the highest quality meat from trusted farms and suppliers, ensuring every product meets rigorous freshness standards. Our meat is carefully selected and processed with strict hygiene protocols so that you get premium cuts every time.</p>
            </div>
            <div className="why-feature">
              <h5><strong>2. Sustainably Sourced & Responsibly Processed</strong></h5>
              <p>Freshiouz partners with local farmers who practice ethical and sustainable farming. This means you’re not only getting top-quality meat but also supporting environmentally conscious practices.</p>
            </div>
            <div className="why-feature">
              <h5><strong>3. Delivered to You, Fresh and Fast</strong></h5>
              <p>Our efficient delivery system ensures that your meat arrives fresh, not frozen, so you can start cooking with ingredients that retain their natural flavor and texture. With Freshiouz, freshness is guaranteed.</p>
            </div>
            <div className="why-feature">
              <h5><strong>4. Wide Range of Choices</strong></h5>
              <p>From poultry to mutton and seafood, Freshiouz offers a wide variety to meet all your culinary needs. Explore our selection to find everything from familiar staples to specialty cuts that make meal planning effortless.</p>
            </div>
            <div className="why-feature">
              <h5><strong>5. Stringent Quality Control</strong></h5>
              <p>Every item is processed in state-of-the-art facilities under strict quality control. Our team maintains high standards in packaging and handling to ensure that the meat arrives at your kitchen as fresh as possible.</p>
            </div>
            <div className="why-feature">
              <h5><strong>6. Customer-Centric Service</strong></h5>
              <p>At Freshiouz, we’re committed to making your experience seamless and satisfying. Our friendly support team is here to assist you with any questions or special requests, providing a level of service that puts you first.</p>
            </div>
            <div className="why-feature">
              <h5><strong>7. Fresh Ideas for Every Meal</strong></h5>
              <p>Our website and social media channels are filled with recipes and cooking tips, so you can make the most of your Freshiouz purchase. From weeknight dinners to weekend feasts, we’re here to inspire you.</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default WhyFreshiouz;