import React, { useState, useEffect } from 'react';
import './css/Banner.css';

const messages = [
  'Use Coupon Code: <a href="#">SALE20</a> | Free Delivery on Orders Over ₹1000!',
  'Get 10% Off on Your First Purchase! Use Code: <a href="#">WELCOME10</a>',
  'Limited Time Offer: Free Shipping on All Orders!',
  'Flash Sale: Up to 50% Off on Selected Items! Shop Now!'
];

const Banner = () => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true); // Show the message

    const interval = setInterval(() => {
      setIsVisible(false); // Hide the current message
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setIsVisible(true); // Show the new message
      }, 500); // Delay before showing the new message
    }, 3000); // Change message every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="banner-container">
      <div 
        className={`banner ${isVisible ? 'visible' : ''}`} 
        dangerouslySetInnerHTML={{ __html: messages[messageIndex] }} 
      />
    </div>
  );
};

export default Banner;
