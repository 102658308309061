import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Container, Form, Nav, Navbar, Offcanvas, Dropdown, InputGroup} from 'react-bootstrap';
import logo from './images/Freshiouz_CL-removebg-preview (1).png';
import leg from './images/chicken-leg.png';
import mutton from './images/mutton (1).png';
import fish from './images/seafood.png';
import egg from './images/eggs.png';
import exotic from './images/no-meat.png';
import { FaRegUser } from "react-icons/fa";
import { HiShoppingCart } from "react-icons/hi";
import OtpDrawer from './OtpDrawer';  // Import the OTP Drawer
import './css/Navbar.css';
import Cart from './Cart';
import { IconButton, Tooltip } from '@mui/material';

function OffcanvasExample() {
    const [selectedLocation, setSelectedLocation] = useState("Select Nearest Store");
    const [searchQuery, setSearchQuery] = useState('');
    const [openOtpDrawer, setOpenOtpDrawer] = useState(false); // State for controlling the OTP drawer
    const [openCart, setOpenCart] = useState(false); // State for controlling the Cart drawer
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const handleSearch = () => {
        console.log('Search triggered:', searchQuery);
        // You can add your search logic here
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };
   
    const handleSelect = (location) => {
        setSelectedLocation(location);
        navigate(`/${location}`);
    };

    const categories = [
        { name: 'Poultry', icon: leg, route: '/poultry' },
        { name: 'Mutton', icon: mutton, route: '/mutton' },
        { name: 'Sea Food', icon: fish, route: '/seafood' },
        { name: 'Eggs', icon: egg, route: '/eggs' },
        { name: 'Exotic Meat', icon: exotic, route: '/exotic' },
        { name: 'Others', icon: leg, route: '/others' }
    ];

    return (
        <>
            <Navbar sticky="top"  className="navbar">
                <Container fluid>
                    <Navbar.Brand href="/" style={{ padding: '0' }}>
                        <img
                            src={logo}
                            width="213"
                            height="56"
                            className="d-inline-block align-top"
                            alt="Freshiouz Logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="offcanvasNavbar" />
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="end"
                    >
                        <Offcanvas.Header closeButton />
                        <Offcanvas.Body>
                            <Nav className="justify-content-start flex-grow-1" style={{ margin: '10px 0 0 60px' }}>
                                {/* Location Dropdown */}
                                <Dropdown className="custom-dropdown" style={{ marginRight: '10px' }}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        {selectedLocation}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => handleSelect("Doddathogur")}>Doddathogur</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect("GS Palya")}>GS Palya</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect("Gollahalli")}>Gollahalli</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect("Neeladri")}>Neeladri</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleSelect("Other Location")}>Other Location</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {/* Search Bar */}
                                <InputGroup className="input-group-sm" style={{ position: 'relative', maxWidth: '270px', marginRight: '20px' }}>
                                    <Form.Control
                                        type="search"
                                        placeholder="Search Fresh-iouz"
                                        aria-label="Search"
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onKeyPress={handleKeyPress}
                                        style={{
                                            borderRadius: '20px',
                                            paddingRight: '10px',
                                            backgroundColor: 'white',
                                            border: '1px solid lightgray',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            right: '78px',
                                            top: '45%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                        }}
                                        onClick={handleSearch}
                                    >
                                        <img
                                            src="https://cdn-icons-png.flaticon.com/512/54/54481.png"
                                            alt="search-icon"
                                            style={{ width: '15px', height: '15px', marginBottom: '5px' }}
                                        />
                                    </span>
                                </InputGroup>
                            </Nav>
                            <Nav className="ml-auto">
                                <Nav.Link onClick={() => setOpenOtpDrawer(true)}>
                                    <Tooltip title="Register/Login">
                                        <IconButton>
                                            <FaRegUser style={{ fontSize: '1.5rem', color: '#EC2124' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Nav.Link>
                                <Nav.Link onClick={() => setOpenCart(true)}>
                                    <Tooltip title="Cart">
                                        <IconButton>
                                            <HiShoppingCart style={{ fontSize: '1.5rem', color: '#EC2124' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Nav.Link>
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            {/* Category Menu */}
            <div className="category-menu">
                {categories.map((category) => (
                    <Link
                        to={category.route}
                        key={category.name}
                        className="category-item"
                        style={{
                            margin: '0 15px', // Spacing between items
                            textDecoration: 'none', // Remove underline
                            display: 'flex', // Flex display for proper alignment
                            alignItems: 'center', // Center items vertically
                            opacity: location.pathname === category.route ? 1 : 0.5, // Dim non-active categories
                            transition: 'opacity 0.3s',// Smooth transition effect
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.opacity = 1)} // Full opacity on hover
                        onMouseLeave={(e) => (e.currentTarget.style.opacity = location.pathname === category.route ? 1 : 0.5)} // Reset opacity
                    >
                        <img src={category.icon} alt={`${category.name} icon`} className="category-icon" style={{ marginRight: '5px' }} />
                        <span>{category.name}</span>
                    </Link>
                ))}
            </div>

            {/* OTP Drawer */}
            <OtpDrawer
                open={openOtpDrawer}
                onClose={() => setOpenOtpDrawer(false)}  // Close the drawer
            />
            {/* Cart */}
            <Cart 
                open={openCart}
                onClose={() => setOpenCart(false)} // Close the cart
            />
        </>
    );
}

export default OffcanvasExample;
