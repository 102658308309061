import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Added import for Grid
import './css/Cards.css';
import ChickenCurry from './images/ae6849a6-0699-4617-a963-382e93cf8940.webp';
import MuttonCurry from './images/2fa6b2bd-884e-4ecb-ac32-09bd7134a854.webp';
import Seafood from './images/558079a0-8e58-4dfb-b230-0922fe061fc8.webp';
import Prawn from './images/9ed9bdc2-79da-4666-a09c-54593b6c1bad.webp';
import fillet from './images/d9044282-cff9-40b8-8cd4-2df54accd277.webp';
import leg from './images/b709630c-9d18-4848-99ff-51804760446c.webp';
import shrimp from './images/d8d8e3b9-82cb-444f-a024-9cd20891a45d.webp';
import crab from './images/b443352e-be68-451b-ae81-7d8cc5828f2e.webp';

function ProductCard({ image, title, originalPrice, discountedPrice }) {
  const [quantity, setQuantity] = useState(0); // Start with 0 quantity
  const [isAdded, setIsAdded] = useState(false); // State to check if the product is added to cart

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      // Reset to Add to Cart if quantity is 0
      if (newQuantity === 0) {
        setIsAdded(false);
      }
    }
  };

  const handleAddToCart = () => {
    setIsAdded(true);
    setQuantity(1); // Set quantity to 1 when added to cart
  };

  return (
    <Card
      sx={{
        height:'283px',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      style={{ marginLeft: '43px', marginBottom: '50px' }}
    >
      <CardMedia
        component="img"
        height="100"
        image={image}
        alt={title}
        sx={{ objectFit: 'cover', height: '200px' }}
        onError={(e) => {
          e.target.src = 'path/to/default/image.jpg'; // Default image path
        }}
      />
      <CardContent
        sx={{
          textAlign: 'center',
          padding: '0 0 8px 0px',
        }}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{ fontWeight: 600, marginBottom: '4px', fontFamily: 'Open Sans',color:'#b62a2a' }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <del>₹ {originalPrice}</del>{' '}
          <Typography component="span" sx={{ color: '#000', fontWeight: 600 }}>
            ₹{discountedPrice}
          </Typography>
        </Typography>
      </CardContent>
      <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '4px',
  }}
>
  {!isAdded ? (
    <Button
      size="small" // Set to small size
      variant="contained"
      sx={{
        backgroundColor: '#EC2124',
        color: '#fff',
        padding: '4px 8px', // Adjust padding for a smaller button
        fontWeight: 'bold',
        textTransform: 'none',
        minWidth: '50px', // Set a fixed width for the button
        height: '30px', // Set a fixed height for the button
        marginRight: '44px',
        marginBottom: '5px',
        borderRadius:'25px',
      }}
      onClick={handleAddToCart}
    >
      Add to Cart
    </Button>
  ) : (
    <>
      <Button
        size="small"
        variant="contained"
        sx={{
          width: 30, // Fixed width for decrement button
          height: 30, // Fixed height for decrement button
          backgroundColor: '#EC2124',
          color: '#fff',
          padding: '0',
          fontWeight: 'bold',
          textTransform: 'none',
          minHeight: 'auto',
          minWidth: 'auto',
          marginRight: '10px',
          marginBottom: '5px',
        }}
        onClick={handleDecrement}
      >
        −
      </Button>
      <Typography
        variant="body2"
        component="span"
        sx={{ margin: '0 9px 0 0', color: '#000', fontWeight: 'bold' , fontSize:'17px'}}
      >
        {quantity}
      </Typography>
      <Button
        size="small"
        variant="contained"
        sx={{
          width: 30, // Fixed width for increment button
          height: 30, // Fixed height for increment button
          backgroundColor: '#EC2124',
          color: '#fff',
          padding: '0',
          fontWeight: 'bold',
          textTransform: 'none',
          minHeight: 'auto',
          minWidth: 'auto',
          marginRight: '50px',
        marginBottom: '5px',
        }}
        onClick={handleIncrement}
      >
        +
      </Button>
    </>
  )}
</Box>
    </Card>
  );
}

export default function SeaFoodGrid() {
  const productData = [
    {
      image: ChickenCurry,
      title: 'Chicken Curry Cut (500 g)',
      originalPrice: 300,
      discountedPrice: 280,
    },
    {
      image: MuttonCurry,
      title: 'Mutton Curry Cut (500 g)',
      originalPrice: 822,
      discountedPrice: 799,
    },
    {
      image: Seafood,
      title: 'Indian Salmon (500 g)',
      originalPrice: 950,
      discountedPrice: 900,
    },
    {
      image: Prawn,
      title: 'White Prawns (500 g)',
      originalPrice: 550,
      discountedPrice: 500,
    },
    {
      image: fillet,
      title: 'Fish Fillet (500 g)',
      originalPrice: 600,
      discountedPrice: 550,
    },
    {
      image: leg,
      title: 'Goat Leg (500 g)',
      originalPrice: 1000,
      discountedPrice: 950,
    },
    {
      image: shrimp,
      title: 'Shrimp Cocktail (500 g)',
      originalPrice: 400,
      discountedPrice: 350,
    },
    {
      image: crab,
      title: 'Crab Meat (500 g)',
      originalPrice: 1200,
      discountedPrice: 1150,
    },
  ];

  return (
    <Box sx={{ padding: '16px', textAlign: 'center' }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ color: '#EC2124', fontWeight: '800', marginBottom: '40px' }}
        className="today"
      >
        SeaFood Deal
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {productData.map((product) => (
          <Grid item key={product.title} xs={12} sm={6} md={3}>
            <ProductCard
              image={product.image}
              title={product.title}
              originalPrice={product.originalPrice}
              discountedPrice={product.discountedPrice}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
