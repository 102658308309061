import React from 'react';
import './css/Gallery.css'; // Assuming you will create a CSS file for styling
import fir from './images/21.jpg';
import sec from './images/30.jpg';
import thi from './images/2 (1).jpg';

const ProductGallery = () => {
    return (
        <div className="product-gallery">
            <div className="product-item">
                <img src={fir} alt="Freshiou Sea food" className="product-image" roundedSquare/>
            </div>
            <div className="product-item">
                <img src={sec} alt="Freshiou Fresh Chicken" className="product-image" roundedSquare/>
            </div>
            <div className="product-item">
                <img src={thi} alt="Freshiou Tender Mutton" className="product-image" roundedSquare/>
            
            </div>
          
        </div>
    );
}
export default ProductGallery;
