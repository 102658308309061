import React from 'react';
import { Container, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import './css/InfoSection.css'; // Custom styles
import bad from './images/badge (1).png';
import safe from './images/hygiene (1).png';
import del from './images/fast-delivery (1).png';
import fresh from './images/Chicken thigh boneless .jpg';
import saf from './images/download (2).jpeg';
import deli from './images/FARM(1)(1).png';

const InfoSection = () => {
  // Tooltip content for each section
  const freshnessPopover = (
    <Popover id="popover-freshness">
      <Popover.Body>
        <img src={fresh} alt="Freshness Badge" style={{ maxWidth: '250px', marginBottom: '10px' }} />
        <p style={{fontFamily:'cursive',color:'#b62a2a',textAlign:'center'}}>We ensure that all our products are chilled, never frozen, to retain maximum freshness. Delivered fresh, guaranteed!</p>
      </Popover.Body>
    </Popover>
  );

  const hygienePopover = (
    <Popover id="popover-hygiene">
      <Popover.Body>
        <img src={saf} alt="Hygiene Badge" style={{ maxWidth: '250px', marginBottom: '10px' }} />
        <p style={{fontFamily:'cursive',color:'#b62a2a',textAlign:'center'}}>All products are hygienically cut and packed with strict adherence to safety standards.</p>
      </Popover.Body>
    </Popover>
  );

  const deliveryPopover = (
    <Popover id="popover-delivery">
      <Popover.Body>
        <img src={deli} alt="Fast Delivery Badge" style={{ maxWidth: '250px', marginBottom: '10px' }} />
        <p style={{fontFamily:'cursive',color:'#b62a2a',textAlign:'center'}}>Need it quickly? We ensure super-fast delivery so you can enjoy your meal on time!</p>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container className="info-section" fluid style={{ padding: '0', margin: '23px 0 40px 0' }}>
      <Row className="justify-content-center text-center">
        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
          <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={freshnessPopover}>
            <div className="info-icon" role="button">
              <img src={bad} style={{ maxWidth: '60px' }} alt="Assured freshness" />
            </div>
          </OverlayTrigger>
            <p className="head" role="button">Assured Freshness</p>
          <p>Never frozen, Chilled to retain Freshness</p>
        </Col>
        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
          <OverlayTrigger trigger={['hover', 'focus']} placement="right" overlay={hygienePopover}>
            <div className="info-icon" role="button">
              <img src={safe} style={{ maxWidth: '60px' }} alt="Safe & Hygiene" />
            </div>
          </OverlayTrigger>
            <p className="head" role="button">Safe & Hygiene</p>
          <p>Safely cut and packed hygienically</p>
        </Col>
        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
          <OverlayTrigger trigger={['hover', 'focus']} placement="left" overlay={deliveryPopover}>
            <div className="info-icon" role="button" style={{ marginBottom: '5px' }}>
              <img src={del} style={{ maxWidth: '70px', margin: '0 16px 0px 0' }} alt="Express Delivery" />
            </div>
          </OverlayTrigger>
            <p className="head" role="button">Express Delivery</p>
          <p>Ordering Late? We will make it early</p>
        </Col>
      </Row>
    </Container>
  );
};

export default InfoSection;
