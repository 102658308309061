import React from 'react';
import { Carousel} from 'react-bootstrap'; 
import cover from './images/Welcome Offer2.png'; // Assuming you have this image

// Function 1: Carousel with Individual Intervals
export function IndividualIntervalsExample() {
  return (
    <Carousel >
      <Carousel.Item interval={1000}>
        <img src={cover} alt="First slide" className="d-block w-100" />
      </Carousel.Item>
      <Carousel.Item interval={500}>
        <img src={cover} alt="Second slide"className="d-block w-100 "  />
      </Carousel.Item>
      <Carousel.Item>
        <img src={cover} alt="Third slide" className="d-block w-100" />
      </Carousel.Item>
    </Carousel>
  );
}