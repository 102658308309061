import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CartProvider } from './components/CartContext';  // Import the CartProvider
import OffcanvasExample from './components/Navbar';
import Doddathogur from './components/Doddathogur'; // Adjust the path as necessary
import { IndividualIntervalsExample } from './components/Home';
import InfoSection from './components/InfoSection';
import ActionAreaCard from './components/Cards';
import Footer from './components/Footer';
import ProductGallery from './components/Gallery';
import Banner from './components/Banner';
import ScrollToTop from './components/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChickenGrid from './components/Chicken';
import MuttonGrid from './components/Mutton';
import SeaFoodGrid from './components/SeaFood';
import EggGrid from './components/Eggs';
import './components/css/App.css'
import OtherGrid from './components/Others';
import ExoticGrid from './components/ExoticMeat';
import WhyFreshiouz from './components/WhyFreshiouz';
import Cart from './components/Cart'; // Import your Cart component

function App() {
    return (
        <Router>
            <CartProvider>  {/* Wrap your app with CartProvider */}
                <ScrollToTop/>
                <Banner/>
                {/* Common Navbar for all pages */}
                <OffcanvasExample />
                <Routes>
                    {/* Home route */}
                    <Route path="/" element={
                        <>
                            <IndividualIntervalsExample />
                            <InfoSection />
                            <ActionAreaCard />
                            <ProductGallery/>
                        </>
                    } />

                    {/* Other routes */}
                    <Route path="/DODDATHOGUR" element={<Doddathogur />} />
                    <Route path="/GS PALYA" element={<Doddathogur />} />
                    <Route path="/GOLLAHALLI" element={<Doddathogur />} />
                    <Route path="/NEELADRI" element={<Doddathogur />} />
                    <Route path="/poultry" element={<ChickenGrid />} />
                    <Route path="/mutton" element={<MuttonGrid />} />
                    <Route path="/seafood" element={<SeaFoodGrid />} />
                    <Route path="/eggs" element={<EggGrid />} />
                    <Route path="/others" element={<OtherGrid />} />
                    <Route path="/exotic" element={<ExoticGrid />} />
                    <Route path='/whyfreshiouz' element={<WhyFreshiouz />} />
                    
                    {/* Cart Route */}
                    <Route path="/cart" element={<Cart open={true} onClose={() => {}} />} /> {/* Add the Cart route */}
                </Routes>
                {/* Common Footer for all pages */}
                <Footer />
            </CartProvider>  {/* End CartProvider */}
        </Router>
    );
}

export default App;
