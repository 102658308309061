// components/ScrollToTop.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // Scroll to top when the component mounts (on page refresh)
        window.scrollTo(0,0);
    }, [pathname]); // This will also handle scroll on route changes

    return null;
};

export default ScrollToTop;
