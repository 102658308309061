import React from 'react';
import { Offcanvas, Button } from 'react-bootstrap';
import { useCart } from './CartContext'; // Importing the useCart hook
import './css/Cart.css';  // Add the necessary styling

const Cart = ({ open, onClose }) => {
  const { cartItems } = useCart(); // Accessing cart items from the context

  // Calculate the total price
  const total = cartItems.reduce((sum, item) => sum + item.discountedPrice, 0);

  return (
    <Offcanvas show={open} onHide={onClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Order Summary</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="cart-header">
          <p className="free-delivery" style={{ marginBottom:'0px'}}>Free delivery on all orders above ₹499</p>
        </div>
        <p className="congrats">Congratulations! You've saved ₹85</p>

        {cartItems.map((item) => (
          <div key={item.id} className="cart-item">
            <div className="item-details">
              <p>{item.title}</p>
              <div className="quantity-controls">
                <button className="quantity-btn">-</button>
                <input type="number" className="quantity-input" value={item.quantity} readOnly />
                <button className="quantity-btn">+</button>
              </div>
            </div>
            <div className="cart-pricing">
              <span className="item-weight">1000gms</span>
              <span className="item-price">₹{item.discountedPrice}</span>
              <span className="original-price">₹369</span>
            </div>
          </div>
        ))}

        <hr />

        <div className="bill-details">
          <p>Bill Details</p>
          <div className="bill-row">
            <span>Subtotal</span>
            <span>₹{total}</span>
          </div>
          <div className="bill-row">
            <span>Delivery Charge</span>
            <span>₹39</span>
          </div>
          <div className="bill-row">
            <span>Free delivery</span>
            <span>Free</span>
          </div>
          <div className="total-row">
            <span>Total</span>
            <span className="total-price">₹{total + 39}</span>
          </div>
        </div>

        <Button className="checkout-btn" variant="danger">
          Proceed to Checkout
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default Cart;
