import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import './css/Footer.css'; // Include custom CSS
import logo from './images/Freshiouz CL.png';

const Footer = () => {
  return (
    <footer className="footer py-5">
      <Container >
        <Row className="align-items-start" style={{justifyContent:'space-between',gap:'42px'}}>
          {/* Column 1: Logo and Social Media Icons */}
          <Col md={2} className="mb-4 mb-md-0 text-center text-md-left">
            <div className="logo-section d-flex align-items-center justify-content-center justify-content-md-start mb-3">
              <img
                src={logo} // Replace with actual path
                alt="Freshiouz Logo"
                className="logo-img"
              />
            </div>
            <div className="social-icons">
              <a href="https://www.facebook.com/freshiouzfoods/" className="facebook mr-3"  target="_blank"
    rel="noopener noreferrer">
                <FaFacebook size={24} />
              </a>
              <a href="https://www.instagram.com/freshiouzfoods/" className="instagram mr-3"  target="_blank"
    rel="noopener noreferrer">
                <FaInstagram size={24} />
              </a>
              <a href="https://x.com/freshiouzf" className="twitter mr-3"  target="_blank"
    rel="noopener noreferrer">
                <FaTwitter size={24} />
              </a>
              <a href="https://www.youtube.com/@freshiouzfoods8641" className="youtube mr-3"  target="_blank"
    rel="noopener noreferrer">
                <FaYoutube size={24} />
              </a>
            </div>
          </Col>

         
          {/* Column 3: Store Locations (First Two Stores) */}
          <Col md={2} className="text-center text-md-left">
            <h5 className="footer-section-title">Our Stores</h5>
            <p className="footer-store" style={{color:'#EC2124',fontFamily:'Alice',lineHeight:'13px'}}>
              <strong style={{color:'#EC2124'}}>DODDATHOGUR</strong><br />
              Shop No - 03, LAHARI HEIGHTS, Green House Layout Doddathogur Village,
              Electronic City Phase 1 Land Mark, near Lakshmi Narshima Swamy Temple,
              Bengaluru, Karnataka 560100
            </p>
            <p className="footer-store"  style={{color:'#EC2124',fontFamily:'Alice',lineHeight:'13px'}}>
              <strong style={{color:'#EC2124'}}>GS PALYA</strong><br />
              Shop No 3, Basavanagara Road, Govinda Shetty Palya Rd, near Janatha Clinic,
              Phase 2, Electronic City, Bengaluru, Karnataka 560100
            </p>
          </Col>

          {/* Column 4: Store Locations (Last Two Stores) */}
          <Col md={2} className="text-center text-md-left">
            <p className="footer-store" style={{color:'#EC2124',fontFamily:'Alice',marginTop:'34px',lineHeight:'13px'}}>
              <strong style={{color:'#EC2124'}}>NEELADRI</strong><br />
              Shop No - 03, LAHARI HEIGHTS, Green House Layout Doddathogur Village,
              Electronic City Phase 1 Land Mark, near Lakshmi Narshima Swamy Temple,
              Bengaluru, Karnataka 560100
            </p>
            <p className="footer-store" style={{color:'#EC2124',fontFamily:'Alice',lineHeight:'13px'}}>
              <strong style={{color:'#EC2124'}}>GOLLAHALLI</strong><br />
              NO. 1, NEAR GANESHA TEMPLE, BALAJI LAYOUT CIRCLE, Electronic City Phase 1,
              Gollahalli, Bengaluru, Karnataka 560100
            </p>
          </Col>

           {/* Column 2: Navigation Links */}
           <Col md={2} className=" text-md-left">
           <ul className="list-unstyled footer-nav" style={{ lineHeight: '16px' }}>
  {[
    { name: 'Why Freshiouz?', path: '/whyfreshiouz' },
    { name: 'Careers', path: '/careers' },
    { name: 'Sitemap', path: '/sitemap' },
    { name: 'Refer & Earn', path: '/refer-earn' },
    { name: 'Return Policy', path: '/return-policy' },
    { name: 'Store Locator', path: '/store-locator' },
    { name: 'Reschedule Policy', path: '/reschedule-policy' },
    { name: 'Cancellation Policy', path: '/cancellation-policy' },
  ].map((link) => (
    <li key={link.name}>
      <a href={link.path}>{link.name}</a>
    </li>
  ))}
</ul>
          </Col>

          {/* Column 5: Contact Us and Store Timings */}
          <Col md={2} className="text-center text-md-left" style={{fontSize:'10 px',marginTop:'50px'}}>
            <h10 className="footer-contact">CONTACT US</h10>
            <p style={{marginBottom:'40px',lineHeight:'9px'}}>
              <a href="tel:+91733571717" style={{color:'#EC2124',fontSize:'10px',fontWeight:'500'}}>+91 73357 17117</a><br />
              <a href="mailto:contact@freshiouz.com"  style={{color:'#EC2124',fontSize:'10px',fontWeight:'500'}}>contact@freshiouz.com</a>
            </p>
            <h10 className="footer-timings" >STORE TIMINGS</h10>
            <p  style={{color:'#EC2124',fontSize:'10px',fontWeight:'500'}}>07:00 AM - 09:00 PM</p>
          </Col>

        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
