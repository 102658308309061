import React, { useState } from 'react';
import { Dialog, Button, TextField, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from './images/Freshiouz CL.png';
import './css/OtpDrawer.css';

const OtpDrawer = ({ open, onClose }) => {
    const [mobileNumber, setMobileNumber] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otp, setOtp] = useState('');

    const handleSendOtp = async () => {
        if (mobileNumber.length === 10) {
            try {
                const response = await fetch('/send_otp', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ mobile_number: mobileNumber })
                });
                
                const data = await response.json();
                
                if (response.ok) {
                    alert(data.message);
                    setOtpSent(true);
                } else {
                    alert(data.message || 'Failed to send OTP');
                }
            } catch (error) {
                alert('Error sending OTP');
            }
        } else {
            alert('Please enter a valid 10-digit mobile number.');
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const response = await fetch('/verify_otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ mobile_number: mobileNumber, otp })
            });
            
            const data = await response.json();
            
            if (response.ok) {
                alert(data.message);
                setOtpSent(false);
                setMobileNumber('');
                setOtp('');
                onClose();
            } else {
                alert(data.message || 'Invalid OTP');
            }
        } catch (error) {
            alert('Error verifying OTP');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <div className="otp-modal-header">
                <img src={logo} alt="Logo" className="otp-modal-logo" />
                <IconButton onClick={onClose} className="close-button">
                    <CloseIcon />
                </IconButton>
            </div>

            <Typography variant="h5" align="center" style={{ fontWeight: 'bold', color: '#EC2124', marginTop: '5px', fontSize: '22px' }}>
                Log in / Create account
            </Typography>

            <div className="otp-modal-content">
                <TextField
                    label="Mobile Number"
                    variant="standard"
                    fullWidth
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    disabled={otpSent}
                />
                
                {otpSent ? (
                    <TextField
                        label="Enter OTP"
                        variant="standard"
                        fullWidth
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        style={{ marginTop: '20px' }}
                    />
                ) : null}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={otpSent ? handleVerifyOtp : handleSendOtp}
                    style={{ margin: '20px 0 0', backgroundColor: '#EC2124' }}
                >
                    {otpSent ? 'Verify OTP' : 'Send OTP'}
                </Button>

                <Typography className="otp-message" style={{ paddingTop: '30px', textAlign: 'center' }}>
                    {/* Shop from anywhere, Download our app now! */}
                </Typography>
            </div>
        </Dialog>
    );
};

export default OtpDrawer;
