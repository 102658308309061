import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'; // Added import for Grid
import './css/Cards.css';
import ChickenCurry from './images/ae6849a6-0699-4617-a963-382e93cf8940.webp';
import BriyaniCut from './images/Chicken Briyani Cut.webp';
import Breast from './images/Chicken Breast Boneless.webp';
import BonelessCube from './images/Chicken Boneless cubes.webp';
import Drumstick from './images/Chicken Drumstick.webp';
import Chickenleg from './images/Chicken leg.webp';
import lollipop from './images/Chicken Lollipop.webp';
import Liver from './images/Chicken Liver.webp';
import Wings from './images/Chicken Wings.webp';
import Neck from './images/chicken_neck.jpg';
import Gizzard from './images/Gizzard.jpg';


function ProductCard({ image, title, originalPrice, discountedPrice }) {
  const [quantity, setQuantity] = useState(0); // Start with 0 quantity
  const [isAdded, setIsAdded] = useState(false); // State to check if the product is added to cart

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 0) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      // Reset to Add to Cart if quantity is 0
      if (newQuantity === 0) {
        setIsAdded(false);
      }
    }
  };

  const handleAddToCart = () => {
    setIsAdded(true);
    setQuantity(1); // Set quantity to 1 when added to cart
  };

  return (
    <Card
      sx={{
        height:'283px',
        position: 'relative',
        borderRadius: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      style={{ marginLeft: '43px', marginBottom: '50px' }}
    >
      <CardMedia
        component="img"
        height="100"
        image={image}
        alt={title}
        sx={{ objectFit: 'cover', height: '200px' }}
        onError={(e) => {
          e.target.src = 'path/to/default/image.jpg'; // Default image path
        }}
      />
      <CardContent
        sx={{
          textAlign: 'center',
          padding: '0 0 8px 0px',
        }}
      >
        <Typography
          variant="body2"
          component="div"
          sx={{ fontWeight: 600, marginBottom: '4px', fontFamily: 'Open Sans',color:'#b62a2a' }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <del>₹ {originalPrice}</del>{' '}
          <Typography component="span" sx={{ color: '#000', fontWeight: 600 }}>
            ₹{discountedPrice}
          </Typography>
        </Typography>
      </CardContent>
      <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '4px',
  }}
>
  {!isAdded ? (
    <Button
      size="small" // Set to small size
      variant="contained"
      sx={{
        backgroundColor: '#EC2124',
        color: '#fff',
        padding: '4px 8px', // Adjust padding for a smaller button
        fontWeight: 'bold',
        textTransform: 'none',
        minWidth: '60px', // Set a fixed width for the button
        height: '30px', // Set a fixed height for the button
        marginRight: '44px',
        marginBottom: '5px',
        borderRadius:'25px',
      }}
      onClick={handleAddToCart}
    >
      Add
    </Button>
  ) : (
    <>
      <Button
        size="small"
        variant="contained"
        sx={{
          width: 30, // Fixed width for decrement button
          height: 30, // Fixed height for decrement button
          backgroundColor: '#EC2124',
          color: '#fff',
          padding: '0',
          fontWeight: 'bold',
          textTransform: 'none',
          minHeight: 'auto',
          minWidth: 'auto',
          marginRight: '10px',
          marginBottom: '5px',
        }}
        onClick={handleDecrement}
      >
        −
      </Button>
      <Typography
        variant="body2"
        component="span"
        sx={{ margin: '0 9px 0 0', color: '#000', fontWeight: 'bold' , fontSize:'17px'}}
      >
        {quantity}
      </Typography>
      <Button
        size="small"
        variant="contained"
        sx={{
          width: 30, // Fixed width for increment button
          height: 30, // Fixed height for increment button
          backgroundColor: '#EC2124',
          color: '#fff',
          padding: '0',
          fontWeight: 'bold',
          textTransform: 'none',
          minHeight: 'auto',
          minWidth: 'auto',
          marginRight: '50px',
        marginBottom: '5px',
        }}
        onClick={handleIncrement}
      >
        +
      </Button>
    </>
  )}
</Box>
    </Card>
  );
}

export default function ProductGrid() {
  const productData = [
    {
      image: ChickenCurry,
      title: 'Chicken Curry Cut (500 g)',
      originalPrice: 300,
      discountedPrice: 280,
    },
    {
      image: BriyaniCut,
      title: 'Chicken Briyani Cut (500 g)',
      originalPrice: 822,
      discountedPrice: 799,
    },
    {
      image: Breast,
      title: 'Chicken Breast Boneless (500 g)',
      originalPrice: 950,
      discountedPrice: 900,
    },
    { 
      image: BonelessCube,
      title: 'Chicken Boneless Cubes (500 g)',
      originalPrice: 550,
      discountedPrice: 500,
    },
    {
      image: Drumstick,
      title: 'Chicken Drumsticks (500 g)',
      originalPrice: 600,
      discountedPrice: 550,
    },
    {
      image: Chickenleg,
      title: 'Chicken whole leg (500 g)',
      originalPrice: 1000,
      discountedPrice: 950,
    },
    {
      image: lollipop,
      title: 'Chicken Lollipop Cut (500 g)',
      originalPrice: 400,
      discountedPrice: 350,
    },
    {
      image: Liver,
      title: 'Chicken Liver (500 g)',
      originalPrice: 1200,
      discountedPrice: 1150,
    },
    {
      image: Wings,
      title: 'Chicken Wings (500 g)',
      originalPrice: 1200,
      discountedPrice: 1150,
    },
    {
      image: Neck,
      title: 'Chicken Neck (500 g)',
      originalPrice: 1200,
      discountedPrice: 1150,
    },
    {
      image: Gizzard,
      title: 'Chicken Gizzard (500 g)',
      originalPrice: 1200,
      discountedPrice: 1150,
    },
  ];

  return (
    <Box sx={{ padding: '16px', textAlign: 'center' }}>
      <Typography
        variant="h5"
        component="h2"
        sx={{ color: '#EC2124', fontWeight: '800', marginBottom: '40px' }}
        className="today"
      >
        Chicken Deal
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        {productData.map((product) => (
          <Grid item key={product.title} xs={12} sm={6} md={3}>
            <ProductCard
              image={product.image}
              title={product.title}
              originalPrice={product.originalPrice}
              discountedPrice={product.discountedPrice}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
